<template>
  <b-form @submit.prevent="addServerApp">
    <b-form-group label="Nom" label-for="name-input">
      <b-form-input
          id="name-input"
          v-model="$v.serverApp.name.$model"
          type="text"
          required
          placeholder="Renseignez un nom d'application serveur"
          :state="$v.serverApp.name.$dirty ? !$v.serverApp.name.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.serverApp.name.$invalid">
        <span v-if="!$v.serverApp.name.required">Vous devez renseigner ce champ</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      serverApp: {
        name: null,
      },
    };
  },
  validations: {
    serverApp: {
      name: {required},
    },
  },
  methods: {
    async addServerApp() {
      try {
        await this.$store.dispatch("serverApps/addServerApp", this.serverApp);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la création de l'application serveur",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Application serveur ajoutée",
      });
      await this.$router.push({name: "serverApps"});
    },
  },
};
</script>
