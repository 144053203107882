<template>
  <AddServerAppForm/>
</template>

<script>
import AddServerAppForm from "@/components/serverApps/AddServerAppForm";

export default {
  components: {
    AddServerAppForm,
  },
};
</script>

